export const LayerStyle = {
  id: "pattern-layer",
  type: "fill",
  source: "source",
  paint: {
    "fill-color": "#ffffff",
    "fill-opacity": 0.05,
  },
};

export const MatchLineStyle = {
  id: "route",
  type: "line",
  source: "route",
  layout: {
    "line-join": "round",
    "line-cap": "round",
  },
  paint: {
    // "line-color": "#03AA46", red : #EE4B2B, blue:#0700c4,
    "line-color": "#FF0000",
    "line-width": 2,
    "line-opacity": 0.5,
  },
};

export const LineStyle = {
  id: "route",
  type: "line",
  source: "route",
  layout: {
    "line-join": "round",
    "line-cap": "round",
  },
  paint: {
    "line-color": "#000",
    "line-width": 4,
  },
};
