export const StateIds = {
  NSW: "fce5b717-ce0d-4169-8b92-9d311ca844f8",
  NT: "4ff8778b-6a1e-4a23-81cb-66ff52cf0537",
  QLD: "e868429e-b3dc-4de6-8367-78ac9657c0c7",
  SA: "66ebc6fc-38c3-42c9-9aca-9c42dd5a41ca",
  TAS: "b360f801-68b5-4a24-8591-090cac4a5f1e",
  VIC: "6fe59eca-109a-41ef-b5f6-bbb1c680568e",
  WA: "e5a92073-ac68-4c21-8b31-9672f0f8ddbd",
};

export const houseTypes = [
  "CTGE",
  "DUPL",
  "HSE",
  "TNHS",
  "VLLA",
  "BNGW",
  "LOFT",
  "PTHS",
  "STU",
  null,
];

export const unitTypes = ["APT", "UNIT", "FLAT", "LOFT"];

export const allowedFlatTypeLists = [
  "CTGE",
  "DUPL",
  "HSE",
  "TNHS",
  "VLLA",
  "BNGW",
  "LOFT",
  "PTHS",
  "STU",
  "APT",
  "UNIT",
  "FLAT",
  "LOFT",
  null,
];

export const flatTypeSelection = [
  { name: "APARTMENT", code: "APT" },
  { name: "BUNGALOW", code: "BNGW" },
  { name: "COTTAGE", code: "CTGE" },
  { name: "DUPLEX", code: "DUPL" },
  { name: "FLAT", code: "FLAT" },
  { name: "HOUSE", code: "HSE" },
  { name: "UNIT", code: "UNIT" },
  { name: "VILLA", code: "VLLA" },
  { name: "TOWNHOUSE", code: "TNHS" },
  { name: "REAR", code: "REAR" },
];

export const streetTypes = [
  { text: "ROAD", value: "ROAD" },
  { text: "DRIVE", value: "DRIVE" },
  { text: "PLACE", value: "PLACE" },
  { text: "STREET", value: "STREET" },
  { text: "CIRCUIT", value: "CIRCUIT" },
  { text: "AVENUE", value: "AVENUE" },
  { text: "COURT", value: "COURT" },
  { text: "MEWS", value: "MEWS" },
  { text: "CRESCENT", value: "CRESCENT" },
  { text: "WAY", value: "WAY" },
  { text: "LANE", value: "LANE" },
  { text: "GROVE", value: "GROVE" },
  { text: "CLOSE", value: "CLOSE" },
  { text: "BEND", value: "BEND" },
  { text: "NOOK", value: "NOOK" },
];

export const streetTypeList = [
  { text: "HIKE", value: "HIKE" },
  { text: "AIRWALK", value: "AIRWALK" },
  { text: "BULL", value: "BULL" },
  { text: "TRIANGLE", value: "TRIANGLE" },
  { text: "FLATS", value: "FLATS" },
  { text: "ACRE", value: "ACRE" },
  { text: "BRANCH", value: "BRANCH" },
  { text: "BRIDGE", value: "BRIDGE" },
  { text: "CROOK", value: "CROOK" },
  { text: "CRUISEWAY", value: "CRUISEWAY" },
  { text: "ACCESS", value: "ACCESS" },
  { text: "ALLEY", value: "ALLEY" },
  { text: "ALLEYWAY", value: "ALLEYWAY" },
  { text: "AMBLE", value: "AMBLE" },
  { text: "APPROACH", value: "APPROACH" },
  { text: "ARCADE", value: "ARCADE" },
  { text: "ARTERIAL", value: "ARTERIAL" },
  { text: "ARTERY", value: "ARTERY" },
  { text: "AVENUE", value: "AVENUE" },
  { text: "BANAN", value: "BANAN" },
  { text: "BANK", value: "BANK" },
  { text: "BAY", value: "BAY" },
  { text: "BEACH", value: "BEACH" },
  { text: "BEND", value: "BEND" },
  { text: "BOARDWALK", value: "BOARDWALK" },
  { text: "BOULEVARD", value: "BOULEVARD" },
  { text: "BOULEVARDE", value: "BOULEVARDE" },
  { text: "BOWL", value: "BOWL" },
  { text: "BRACE", value: "BRACE" },
  { text: "BRAE", value: "BRAE" },
  { text: "BREAK", value: "BREAK" },
  { text: "BRETT", value: "BRETT" },
  { text: "BROADWAY", value: "BROADWAY" },
  { text: "BROW", value: "BROW" },
  { text: "BYPASS", value: "BYPASS" },
  { text: "BYWAY", value: "BYWAY" },
  { text: "CAUSEWAY", value: "CAUSEWAY" },
  { text: "CENTRE", value: "CENTRE" },
  { text: "CENTREWAY", value: "CENTREWAY" },
  { text: "CHASE", value: "CHASE" },
  { text: "CIRCLE", value: "CIRCLE" },
  { text: "CIRCLET", value: "CIRCLET" },
  { text: "CIRCUIT", value: "CIRCUIT" },
  { text: "CIRCUS", value: "CIRCUS" },
  { text: "CLOSE", value: "CLOSE" },
  { text: "CLUSTER", value: "CLUSTER" },
  { text: "COMMON", value: "COMMON" },
  { text: "CONCORD", value: "CONCORD" },
  { text: "CONCOURSE", value: "CONCOURSE" },
  { text: "CONNECTION", value: "CONNECTION" },
  { text: "COPSE", value: "COPSE" },
  { text: "CORNER", value: "CORNER" },
  { text: "CORSO", value: "CORSO" },
  { text: "COURSE", value: "COURSE" },
  { text: "COURT", value: "COURT" },
  { text: "COURTYARD", value: "COURTYARD" },
  { text: "COVE", value: "COVE" },
  { text: "CRESCENT", value: "CRESCENT" },
  { text: "CREST", value: "CREST" },
  { text: "CRIEF", value: "CRIEF" },
  { text: "CROSS", value: "CROSS" },
  { text: "CROSSING", value: "CROSSING" },
  { text: "CUL-DE-SAC", value: "CUL-DE-SAC" },
  { text: "CUTTING", value: "CUTTING" },
  { text: "DALE", value: "DALE" },
  { text: "DELL", value: "DELL" },
  { text: "DENE", value: "DENE" },
  { text: "DEVIATION", value: "DEVIATION" },
  { text: "DIP", value: "DIP" },
  { text: "DIVIDE", value: "DIVIDE" },
  { text: "DOCK", value: "DOCK" },
  { text: "DOMAIN", value: "DOMAIN" },
  { text: "DOWN", value: "DOWN" },
  { text: "DOWNS", value: "DOWNS" },
  { text: "DRIVE", value: "DRIVE" },
  { text: "DRIVEWAY", value: "DRIVEWAY" },
  { text: "EASEMENT", value: "EASEMENT" },
  { text: "EDGE", value: "EDGE" },
  { text: "ELBOW", value: "ELBOW" },
  { text: "END", value: "END" },
  { text: "ENTRANCE", value: "ENTRANCE" },
  { text: "ESPLANADE", value: "ESPLANADE" },
  { text: "ESTATE", value: "ESTATE" },
  { text: "EXPRESSWAY", value: "EXPRESSWAY" },
  { text: "FAIRWAY", value: "FAIRWAY" },
  { text: "FIRETRACK", value: "FIRETRACK" },
  { text: "FIRETRAIL", value: "FIRETRAIL" },
  { text: "FLAT", value: "FLAT" },
  { text: "FOLLOW", value: "FOLLOW" },
  { text: "FOOTWAY", value: "FOOTWAY" },
  { text: "FORD", value: "FORD" },
  { text: "FORESHORE", value: "FORESHORE" },
  { text: "FORK", value: "FORK" },
  { text: "FORMATION", value: "FORMATION" },
  { text: "FREEWAY", value: "FREEWAY" },
  { text: "FRONTAGE", value: "FRONTAGE" },
  { text: "GAP", value: "GAP" },
  { text: "GARDEN", value: "GARDEN" },
  { text: "GARDENS", value: "GARDENS" },
  { text: "GATE", value: "GATE" },
  { text: "GATEWAY", value: "GATEWAY" },
  { text: "GLADE", value: "GLADE" },
  { text: "GLEN", value: "GLEN" },
  { text: "GRANGE", value: "GRANGE" },
  { text: "GREEN", value: "GREEN" },
  { text: "GROVE", value: "GROVE" },
  { text: "GULLY", value: "GULLY" },
  { text: "HAVEN", value: "HAVEN" },
  { text: "HEATH", value: "HEATH" },
  { text: "HEIGHTS", value: "HEIGHTS" },
  { text: "HIGHROAD", value: "HIGHROAD" },
  { text: "HIGHWAY", value: "HIGHWAY" },
  { text: "HILL", value: "HILL" },
  { text: "HOLLOW", value: "HOLLOW" },
  { text: "HUB", value: "HUB" },
  { text: "INLET", value: "INLET" },
  { text: "INTERCHANGE", value: "INTERCHANGE" },
  { text: "ISLAND", value: "ISLAND" },
  { text: "JUNCTION", value: "JUNCTION" },
  { text: "KEY", value: "KEY" },
  { text: "KEYS", value: "KEYS" },
  { text: "KNOLL", value: "KNOLL" },
  { text: "LANDING", value: "LANDING" },
  { text: "LANE", value: "LANE" },
  { text: "LANEWAY", value: "LANEWAY" },
  { text: "LINE", value: "LINE" },
  { text: "LINK", value: "LINK" },
  { text: "LOOKOUT", value: "LOOKOUT" },
  { text: "LOOP", value: "LOOP" },
  { text: "LYNNE", value: "LYNNE" },
  { text: "MALL", value: "MALL" },
  { text: "MANOR", value: "MANOR" },
  { text: "MEAD", value: "MEAD" },
  { text: "MEANDER", value: "MEANDER" },
  { text: "MEWS", value: "MEWS" },
  { text: "MOTORWAY", value: "MOTORWAY" },
  { text: "NOOK", value: "NOOK" },
  { text: "OUTLET", value: "OUTLET" },
  { text: "OUTLOOK", value: "OUTLOOK" },
  { text: "OVAL", value: "OVAL" },
  { text: "PARADE", value: "PARADE" },
  { text: "PARADISE", value: "PARADISE" },
  { text: "PARK", value: "PARK" },
  { text: "PARKWAY", value: "PARKWAY" },
  { text: "PASS", value: "PASS" },
  { text: "PASSAGE", value: "PASSAGE" },
  { text: "PATH", value: "PATH" },
  { text: "PATHWAY", value: "PATHWAY" },
  { text: "PIAZZA", value: "PIAZZA" },
  { text: "PLACE", value: "PLACE" },
  { text: "PLAZA", value: "PLAZA" },
  { text: "POCKET", value: "POCKET" },
  { text: "POINT", value: "POINT" },
  { text: "PORT", value: "PORT" },
  { text: "PRECINCT", value: "PRECINCT" },
  { text: "PROMENADE", value: "PROMENADE" },
  { text: "PURSUIT", value: "PURSUIT" },
  { text: "QUADRANT", value: "QUADRANT" },
  { text: "QUAY", value: "QUAY" },
  { text: "QUAYS", value: "QUAYS" },
  { text: "RAMBLE", value: "RAMBLE" },
  { text: "RAMP", value: "RAMP" },
  { text: "RANGE", value: "RANGE" },
  { text: "REACH", value: "REACH" },
  { text: "RESERVE", value: "RESERVE" },
  { text: "REST", value: "REST" },
  { text: "RETREAT", value: "RETREAT" },
  { text: "RETURN", value: "RETURN" },
  { text: "RIDE", value: "RIDE" },
  { text: "RIDGE", value: "RIDGE" },
  { text: "RISE", value: "RISE" },
  { text: "RISING", value: "RISING" },
  { text: "RIVER", value: "RIVER" },
  { text: "ROAD", value: "ROAD" },
  { text: "ROADWAY", value: "ROADWAY" },
  { text: "ROTARY", value: "ROTARY" },
  { text: "ROUND", value: "ROUND" },
  { text: "ROUTE", value: "ROUTE" },
  { text: "ROW", value: "ROW" },
  { text: "ROWE", value: "ROWE" },
  { text: "RUE", value: "RUE" },
  { text: "RUN", value: "RUN" },
  { text: "SERVICEWAY", value: "SERVICEWAY" },
  { text: "SHUNT", value: "SHUNT" },
  { text: "SKYLINE", value: "SKYLINE" },
  { text: "SLOPE", value: "SLOPE" },
  { text: "SPUR", value: "SPUR" },
  { text: "SQUARE", value: "SQUARE" },
  { text: "STEPS", value: "STEPS" },
  { text: "STRAIGHT", value: "STRAIGHT" },
  { text: "STRAIT", value: "STRAIT" },
  { text: "STREET", value: "STREET" },
  { text: "STRIP", value: "STRIP" },
  { text: "SUBWAY", value: "SUBWAY" },
  { text: "TARN", value: "TARN" },
  { text: "TERRACE", value: "TERRACE" },
  { text: "THOROUGHFARE", value: "THOROUGHFARE" },
  { text: "THROUGHWAY", value: "THROUGHWAY" },
  { text: "TOLLWAY", value: "TOLLWAY" },
  { text: "TOP", value: "TOP" },
  { text: "TOR", value: "TOR" },
  { text: "TRACK", value: "TRACK" },
  { text: "TRAIL", value: "TRAIL" },
  { text: "TRUNKWAY", value: "TRUNKWAY" },
  { text: "TURN", value: "TURN" },
  { text: "TWIST", value: "TWIST" },
  { text: "UNDERPASS", value: "UNDERPASS" },
  { text: "VALE", value: "VALE" },
  { text: "VALLEY", value: "VALLEY" },
  { text: "VIADUCT", value: "VIADUCT" },
  { text: "VIEW", value: "VIEW" },
  { text: "VIEWS", value: "VIEWS" },
  { text: "VILLA", value: "VILLA" },
  { text: "VISTA", value: "VISTA" },
  { text: "WALK", value: "WALK" },
  { text: "WALKWAY", value: "WALKWAY" },
  { text: "WATERS", value: "WATERS" },
  { text: "WATERWAY", value: "WATERWAY" },
  { text: "WAY", value: "WAY" },
  { text: "WHARF", value: "WHARF" },
  { text: "WOODS", value: "WOODS" },
  { text: "WYND", value: "WYND" },
  { text: "WOOD", value: "WOOD" },
  { text: "CUT", value: "CUT" },
  { text: "DASH", value: "DASH" },
  { text: "DISTRIBUTOR", value: "DISTRIBUTOR" },
  { text: "EXTENSION", value: "EXTENSION" },
  { text: "FIREBREAK", value: "FIREBREAK" },
  { text: "FIRELINE", value: "FIRELINE" },
  { text: "HILLS", value: "HILLS" },
  { text: "LADDER", value: "LADDER" },
  { text: "LEAD", value: "LEAD" },
  { text: "LEADER", value: "LEADER" },
  { text: "MILE", value: "MILE" },
  { text: "TRAMWAY", value: "TRAMWAY" },
  { text: "TRAVERSE", value: "TRAVERSE" },
  { text: "TUNNEL", value: "TUNNEL" },
  { text: "VILLAS", value: "VILLAS" },
  { text: "BROADWALK", value: "BROADWALK" },
  { text: "COLONNADE", value: "COLONNADE" },
  { text: "COMMONS", value: "COMMONS" },
  { text: "FRONT", value: "FRONT" },
  { text: "HARBOUR", value: "HARBOUR" },
  { text: "MEW", value: "MEW" },
  { text: "NORTH", value: "NORTH" },
  { text: "NULL", value: "NULL" },
  { text: "PALMS", value: "PALMS" },
  { text: "PENINSULA", value: "PENINSULA" },
  { text: "QUAD", value: "QUAD" },
  { text: "REEF", value: "REEF" },
  { text: "RIGHT OF WAY", value: "RIGHT OF WAY" },
  { text: "RING", value: "RING" },
  { text: "ROADS", value: "ROADS" },
  { text: "SOUTH", value: "SOUTH" },
  { text: "STRAND", value: "STRAND" },
  { text: "VERGE", value: "VERGE" },
  { text: "VILLAGE", value: "VILLAGE" },
  { text: "VUE", value: "VUE" },
  { text: "WADE", value: "WADE" },
  { text: "YARD", value: "YARD" },
  { text: "MART", value: "MART" },
  { text: "BUSWAY", value: "BUSWAY" },
  { text: "EAST", value: "EAST" },
  { text: "WEST", value: "WEST" },
  { text: "PART", value: "PART" },
  { text: "CONNECTOR", value: "CONNECTOR" },
  { text: "BOUNDARY", value: "BOUNDARY" },
  { text: "CROSSOVER", value: "CROSSOVER" },
  { text: "MAZE", value: "MAZE" },
];


  //This function takes in latitude and longitude of two location and returns the distance between them as the crow flies (in km)
  // function calcCrow(lat1, lon1, lat2, lon2) {
  //   var R = 6371; // km
  //   var dLat = toRad(lat2 - lat1);
  //   var dLon = toRad(lon2 - lon1);
  //   var lat11 = toRad(lat1);
  //   var lat21 = toRad(lat2);

  //   var a =
  //     Math.sin(dLat / 2) * Math.sin(dLat / 2) +
  //     Math.sin(dLon / 2) *
  //       Math.sin(dLon / 2) *
  //       Math.cos(lat11) *
  //       Math.cos(lat21);
  //   var cLng = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  //   var d = R * cLng;
  //   return d;
  // }

  // Converts numeric degrees to radians
  // function toRad(Value) {
  //   return (Value * Math.PI) / 180;
  // }

  

    // if (e.type !== "marker") {
    //   setIsDrawingVisible(false);
    //   console.log("in true section", e.overlay.position.lat().toFixed(6));
    // }

    // if (e.type === 'circle') {
    //   var radius = e.overlay.getRadius();
    // }