import axios from "axios";

const MatchApi = "https://api.mapbox.com/directions/v5";
const profile = "mapbox/cycling";

export async function MatchingData(coords) {
  let url = `${MatchApi}/${profile}/${coords}?annotations=maxspeed&overview=full&geometries=geojson&access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`;
  let data = await axios.get(url);

  let geoData = data.data.routes[0].geometry;
  return {type: 'Feature', geometry: geoData};
}

export const GetCoordinates = (feature) => {
  let coord = feature.geometry.coordinates.join(",");
  let array = coord.split(",");
  let data = [];
  for (let i = 0; i < array.length; i = i + 2) {
    const element = array[i] + " " + array[i + 1];
    data.push(element);
  }
  return data.join(",");
};

