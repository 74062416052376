export const link = {
  base_url: "https://api.orbigo.io/",
  // base_url: "http://localhost:9000/",
  version: "3.0/tvts/",
  getRegionList: "nearbylgaslist",
  getRegionById: "lgainfosbylgaid",
  getNearbySuburb: "nearbysuburblist",
  getStreetData: "streetData",
  getStreetDataById: "streetDataById",
  getLGAByStateId: "regions",
  getSuburbDetails: "suburbinfos",
  getSuburbById: "suburbById",
  getSuburbsByState: "suburbsByState",
  getStreetDataByPolygon: "streetDataWithinPolygon",
};
