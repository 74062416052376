import React, { useEffect, useState, useCallback } from "react";
import {
  Card,
  Select,
  Form,
  Typography,
  message,
  Button,
  Spin,
  Col,
  Row,
  Radio,
  Switch,
} from "antd";

import { RedoOutlined } from "@ant-design/icons";
import Meta from "antd/lib/card/Meta";

import API from "../server/api";
import { link } from "../server/link";
// import { useReactToPrint } from "react-to-print";

// State ID Data
import { StateIds, houseTypes, unitTypes } from "../data/dataModel";
import CustomTable from "../components/table";
import MapGL from "./MapGL";
import { MatchingData } from "../utils/MapUtil";

import { useHistory } from "react-router-dom";
import { auth } from "../server/firebase";
import { useAuthState } from "react-firebase-hooks/auth";

const { Option } = Select;
const { Text } = Typography;

const Home = () => {
  const [form] = Form.useForm();
  // let googleRef = useRef();

  // State selections
  const [state, setState] = useState("NSW");

  // const [mapRef, setMapRef] = useState(null);
  const [isSuburb, setIsSuburb] = useState(false);
  const [regionList, setRegionList] = useState([]);
  const [streetRawData, setStreetRawData] = useState([]);
  const [suburbList, setSuburbList] = useState([]);
  const [streetList, setStreetList] = useState([]);
  const [isDrawingVisible, setIsDrawingVisible] = useState(true);
  const [centerLoc, setCenterLoc] = useState({
    lat: parseFloat(localStorage.getItem("lat")),
    lng: parseFloat(localStorage.getItem("lng")),
  });
  const [zoom, setZoom] = useState(parseFloat(localStorage.getItem("zoom")));

  const [suburbBoundary, setSuburbBoundary] = useState([]);
  const [regionBoundary, setRegionBoundary] = useState([]);

  // for count of houses
  const [totalCount, setTotalCount] = useState(0);
  const [selectedCount, setSelectedCount] = useState(0);

  // for loading the datasets
  const [regionLoading, setRegionLoading] = useState(false);
  const [suburbLoading, setSuburbLoading] = useState(false);
  const [addressesLoading, setAddressesLoading] = useState(false);

  const [defaultSuburb, setDefaultSuburb] = useState([]);

  // Filters
  const [flatType, setFlatType] = useState("BOTH");
  const [confidence, setConfidence] = useState("2");

  // for direction render
  const [directions, setDirections] = useState([]);
  const [selectedDirections, setSelectedDirections] = useState([]);
  const [isStreetVisible, setIsStreetVisible] = useState(false);

  const navigate = useHistory();
  const [user, loading] = useAuthState(auth);

  useEffect(() => {
    if (!user) navigate.replace("/login");
  }, [user, loading, navigate]);

  const changeState = (e) => {
    setRegionList([]);
    setState(e.target.value);
    var selectedState = StateIds.NSW;
    if (e.target.value === "VIC") {
      selectedState = StateIds.VIC;
    }

    setRegionLoading(true);
    var suburbUrl = `${
      link.version + link.getSuburbsByState
    }?stateId=${selectedState}`;

    API.get(suburbUrl)
      .then((res) => {
        let data = res.data.geodto;
        let suburbData = [];
        data.forEach((element) => {
          suburbData.push({
            id: element.Id,
            name: element.name,
            location: "",
            regionType: "Suburb",
            state: "NSW",
          });
        });
        const uniqueSuburbsID = [
          ...new Map(suburbData.map((item) => [item.id, item])).values(),
        ];
        setRegionList((prevState) => [...prevState, ...uniqueSuburbsID]);
        setRegionLoading(false);
      })
      .catch((error) => {
        console.error("There is an Error : ", error);
        setRegionLoading(false);
      });

    let cLat = localStorage.getItem("lat");
    let cLng = localStorage.getItem("lng");
    let zm = localStorage.getItem("zoom");

    if (zm) setZoom(parseFloat(zm));
    if (cLat && cLng) {
      setCenterLoc({ lat: parseFloat(cLat), lng: parseFloat(cLng) });
    } else setCenterLoc({ lat: -33.865143, lng: 151.2099 });
  };

  useEffect(() => {
    localStorage.clear();
    setRegionLoading(true);
    var suburbUrl = `${link.version + link.getSuburbsByState}?stateId=${
      StateIds.NSW
    }`;

    API.get(suburbUrl)
      .then((res) => {
        let data = res.data.geodto;
        let suburbData = [];
        data.forEach((element) => {
          suburbData.push({
            id: element.Id,
            name: element.name,
            location: "",
            regionType: "Suburb",
            state: "NSW",
          });
        });
        const uniqueSuburbsID = [
          ...new Map(suburbData.map((item) => [item.id, item])).values(),
        ];
        setRegionList((prevState) => [...prevState, ...uniqueSuburbsID]);
        setRegionLoading(false);
      })
      .catch((error) => {
        console.error("There is an Error : ", error);
        setRegionLoading(false);
      });

    localStorage.setItem("zoom", 12);
    let cLat = localStorage.getItem("lat");
    let cLng = localStorage.getItem("lng");
    let zm = localStorage.getItem("zoom");
    if (zm) {
      setZoom(parseFloat(zm));
    }
    if (cLat && cLng) {
      setCenterLoc({ lat: parseFloat(cLat), lng: parseFloat(cLng) });
    } else setCenterLoc({ lat: -33.865143, lng: 151.2099 });
  }, []);

  const regionOptions = regionList
    .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
    .map((d, i) => (
      <Option
        className="select-option-text"
        key={d.id + i}
        value={JSON.stringify(d)}
      >
        <div className="select-option-container">
          <div className="region-text">
            <h3>{d.name}</h3>
            <h6>{`-(${state})`}</h6>
          </div>
          <h5>{d.regionType}</h5>
        </div>
      </Option>
    ));

  const resetSelectFields = useCallback(
    (isAll) => {
      if (isAll) {
        form.resetFields();
      } else {
        form.resetFields(["suburb"]);
      }
      setDirections([]);
      setSelectedDirections([]);
      setIsStreetVisible(false);
      setAddressesLoading(false);
      setIsSuburb(false);
      setSuburbList([]);
      setStreetList([]);
      setSelectedCount(0);
      setTotalCount(0);
      setSuburbBoundary([]);
      setRegionBoundary([]);
    },
    [setSuburbList, setSelectedDirections, form]
  );

  const onRegionChange = async (value, data) => {
    resetSelectFields(false);
    setSuburbLoading(true);
    let regionData = JSON.parse(value);
    setIsSuburb(true);
    let url = `${link.version + link.getSuburbById}?suburbId=${regionData.id}`;
    await API.get(url)
      .then((res) => {
        var point = res.data.location.split(" ");
        var lng = point[1].split("(")[1];
        var lat = point[2].split(")")[0];

        nearbySuburbRegion(lat, lng);
      })
      .catch((error) => {
        setSuburbLoading(false);
        console.error("There was an error!", error);
        message.error("Please select a different region", 5);
      });
  };

  const nearbySuburbRegion = async (lat, lng) => {
    setCenterLoc({ lat: parseFloat(lat), lng: parseFloat(lng) });
    setZoom(14);
    let url = `${
      link.version + link.getNearbySuburb
    }?lat=${lat}&lng=${lng}&distance=10`;
    await API.get(url)
      .then((res) => {
        setSuburbList(...[], res.data);
        setDefaultSuburb(...[], [JSON.stringify(res.data[0])]);
        onSuburbChange([JSON.stringify(res.data[0])]);
        if (res.data[0].location.length > 0) {
          setCenterLoc({
            lat: parseFloat(res.data[0].location.lat),
            lng: parseFloat(res.data[0].location.lng),
          });
        }
        let boundary = [];
        res.data.forEach((element) => {
          let index = 2;
          if (element.geoBoundary.split("((")[0].trim() === "MULTIPOLYGON") {
            index = 3;
          }
          boundary.push(
            polyBoundary(
              element.geoBoundary.split("(")[index].split(")")[0].split(",")
            )
          );
        });

        setRegionBoundary(...[], boundary);
        setSuburbLoading(false);
      })
      .catch((error) => {
        // your error handling goes here
        setSuburbLoading(false);
        console.error("There was an error!", error);
        message.error("Please select a different region", 5);
      });
  };

  const polyBoundary = (data) => {
    let boundaryList = [];
    data.forEach((element) => {
      let splitLoc = element.split(" ");
      boundaryList.push({
        lat: parseFloat(splitLoc[1]),
        lng: parseFloat(splitLoc[0]),
      });
    });
    return boundaryList;
  };

  useEffect(() => {
    setDirections([]);
    streetList.forEach(async (ele) => {
      let data = [];
      if (ele.polylineData.length > 1) {
        ele.polylineData.forEach((i) => {
          data.push([i.lng, i.lat]);
        });
        let data24 = [];
        if (data.length > 25) {
          const n = Math.floor(data.length / 22);
          data24.push(data[0]);
          for (let i = n; i < data.length - 2; i = i + n + 1) {
            data24.push(data[i]);
          }
          data24.push(data[data.length - 1]);
        } else {
          data24 = data;
        }
        if (isStreetVisible) {
          const result = await MatchingData(data24.join(";"));
          const directionData = { id: ele.addressID, data: result };
          setDirections((prevData) => [...prevData, directionData]);
          setSelectedDirections((prevData) => [...prevData, directionData]);
        }
      }
    });
  }, [streetList, setDirections, setSelectedDirections, isStreetVisible]);

  const suburbOption = suburbList.map((d) => (
    <Option className="select-option-text" key={d.id} value={JSON.stringify(d)}>
      <b>{d.name}</b>
    </Option>
  ));

  const onSuburbChange = async (data) => {
    let boundaryList = [];
    let streetDataList = [];
    setAddressesLoading(true);
    form.resetFields(["flat-types"]);
    setDefaultSuburb(...[], data);

    for (let i = 0; i < data.length; i++) {
      let suburbData = JSON.parse(data[i]);
      let value = suburbData.geoBoundary;
      let boundary = polyBoundary(value.split("(")[2].split(")")[0].split(","));
      boundaryList.push(boundary);

      // const suburbName = suburbData.name.replaceAll(" ", "\\%20");
      let streetIdUrl = `${link.version + link.getStreetDataById}?suburbId=${
        suburbData.id
      }`;
      await API.get(streetIdUrl)
        .then((res) => {
          let streetDataFilter = [];
          let streetTypeLists = [];
          res.data.forEach((element) => {
            if (houseTypes.includes(element.flatTypeCode)) {
              element.flatTypeCode = "HSE";
              streetDataFilter.push(element);
              streetDataList.push(element);
              if (!streetTypeLists.includes(element.streetTypeCode)) {
                streetTypeLists.push(element.streetTypeCode);
              }
            } else if (unitTypes.includes(element.flatTypeCode)) {
              element.flatTypeCode = "UNIT";
              streetDataFilter.push(element);
              streetDataList.push(element);
              if (!streetTypeLists.includes(element.streetTypeCode)) {
                streetTypeLists.push(element.streetTypeCode);
              }
            }
          });
          setAddressesLoading(false);
        })
        .catch((error) => {
          // your error handling goes here
          console.error("There was an error!", error);
          setSuburbLoading(false);
        });
    }
    // console.log(streetDataList, boundaryList);
    setStreetRawData(...[], streetDataList);
    setSuburbBoundary(...[], boundaryList);
  };

  const onDrawChange = async (data) => {
    let streetDataList = [];
    setAddressesLoading(true);
    let cLat = localStorage.getItem("lat");
    let cLng = localStorage.getItem("lng");
    let zm = localStorage.getItem("zoom");
    if (zm) {
      setZoom(parseFloat(zm));
    }
    if (cLat && cLng) {
      setCenterLoc({ lat: parseFloat(cLat), lng: parseFloat(cLng) });
    } else setCenterLoc({ lat: -33.865143, lng: 151.2099 });

    let streetPolyURL = `${
      link.version + link.getStreetDataByPolygon
    }?polygon=${data.coordinates}`;

    await API.get(streetPolyURL)
      .then((res) => {
        let streetDataFilter = [];
        let streetTypeLists = [];
        res.data.forEach((element) => {
          if (houseTypes.includes(element.flatTypeCode)) {
            element.flatTypeCode = "HSE";
            streetDataFilter.push(element);
            streetDataList.push(element);
            if (!streetTypeLists.includes(element.streetTypeCode)) {
              streetTypeLists.push(element.streetTypeCode);
            }
          } else if (unitTypes.includes(element.flatTypeCode)) {
            element.flatTypeCode = "UNIT";
            streetDataFilter.push(element);
            streetDataList.push(element);
            if (!streetTypeLists.includes(element.streetTypeCode)) {
              streetTypeLists.push(element.streetTypeCode);
            }
          }
        });
        setAddressesLoading(false);
      })
      .catch((error) => {
        // your error handling goes here
        console.error("There was an error!", error);
        setAddressesLoading(false);
      });

    // console.log(streetDataList, boundaryList);
    setStreetRawData(...[], streetDataList);
    // setSuburbBoundary(...[], boundaryList);
  };

  useEffect(() => {
    let streetDataList = [];
    let flatList = [];
    let confidenceList = [];
    let streetFilteredData = [];

    if (flatType === "BOTH") {
      flatList.push("HSE");
      flatList.push("UNIT");
    } else {
      flatList.push(flatType);
    }

    if (confidence === "BOTH") {
      confidenceList.push("1");
      confidenceList.push("2");
    } else {
      confidenceList.push(confidence);
    }

    streetRawData.forEach((element) => {
      if (
        flatList.includes(element.flatTypeCode) &&
        confidenceList.includes(element.confidence.toString())
      ) {
        streetFilteredData.push(element);
      }
    });

    setTotalCount(streetFilteredData.length);
    let streetDataSet = streetDataHouseCount(streetFilteredData);
    streetDataSet.forEach((element) => {
      streetDataList.push(element);
    });
    setStreetList(...[], streetDataList);
  }, [streetRawData, flatType, confidence]);

  const streetDataHouseCount = (data) => {
    let newData = [];
    if (data) {
      for (let i = 0; i < data.length; i++) {
        const element = data[i];
        let houseCount = 0;
        let unitCount = 0;
        let lineData = [];
        for (let j = 0; j < data.length; j++) {
          const elements = data[j];
          if (elements.streetName === element.streetName) {
            if (elements.flatTypeCode === "HSE") {
              houseCount++;
            } else {
              unitCount++;
            }
            var point = elements.location.split(" ");
            var lng = point[1].split("(")[1];
            var lat = point[2].split(")")[0];
            lineData.push({
              lat: parseFloat(lat),
              lng: parseFloat(lng),
              numberFirst: elements.numberFirst,
            });
          }
        }

        lineData.sort(function (a, b) {
          return a.numberFirst - b.numberFirst;
        });
        element.polylineData = lineData;
        element.houseCount = houseCount;
        element.unitCount = unitCount;
        newData.push(element);
      }
    }
    const uniqueObjects = [
      ...new Map(newData.map((item) => [item.streetName, item])).values(),
    ];
    return uniqueObjects;
  };

  const onFlatTypeChange = (e) => {
    setFlatType(e.target.value);
  };

  const onConfidenceChange = (e) => {
    setConfidence(e.target.value);
  };

  // const printMap = useReactToPrint({
  //   content: () => googleRef.current,
  // });

  const callback = useCallback(
    (item) => {
      setSelectedCount(item.count);
      setSelectedDirections([]);
      if (directions.length > 0 && isStreetVisible) {
        directions.forEach((ele) => {
          if (item.keys.includes(ele.id)) {
            setSelectedDirections((prevState) => [...prevState, ele]);
            console.log(ele);
          }
        });
      }
    },
    [directions, isStreetVisible, setSelectedDirections]
  );

  return (
    <div className="App-content">
      <div className="content-top">
        <Form
          form={form}
          name="advanced_search"
          className="ant-advanced-search-form"
        >
          <Radio.Group value={state} onChange={changeState}>
            <Radio.Button value="NSW">NSW</Radio.Button>
            <Radio.Button value="VIC">VIC</Radio.Button>
          </Radio.Group>

          {isSuburb ? (
            <Form.Item name="suburb" label="Select Suburb">
              <Spin spinning={suburbLoading}>
                <Select
                  showSearch
                  mode="multiple"
                  style={{ width: 500 }}
                  placeholder="Search to select suburb"
                  defaultValue={defaultSuburb}
                  value={defaultSuburb}
                  optionFilterProp="children"
                  onChange={onSuburbChange}
                  maxTagCount={"responsive"}
                  filterOption={(input, option) =>
                    option.children.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children.props.children
                      .toLowerCase()
                      .localeCompare(
                        optionB.children.props.children.toLowerCase()
                      )
                  }
                >
                  {suburbOption}
                </Select>
              </Spin>
            </Form.Item>
          ) : (
            <Form.Item name="region" label="Select initial suburb">
              <Spin spinning={regionLoading}>
                <Select
                  showSearch
                  style={{ width: 500 }}
                  onChange={onRegionChange}
                  placeholder="Search to select initial region"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.props.children[0].props.children[0].props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children.props.children[0].props.children[0].props.children
                      .toLowerCase()
                      .localeCompare(
                        optionB.children.props.children[0].props.children[0].props.children.toLowerCase()
                      )
                  }
                >
                  {regionOptions}
                </Select>
              </Spin>
            </Form.Item>
          )}
          <Button type="primary" onClick={() => resetSelectFields(true)}>
            Reset
          </Button>
        </Form>
      </div>
      <div className="content-bottom">
        <div className="bottom">
          <Card bordered={false}>
            <Meta
              className="map-container"
              title={
                <div className="select-option-container ">
                  <Row gutter={[24, 24]}>
                    <Col span={12}>
                      <Text type="secondary" level={5}>
                        Do you need Street labels?{" "}
                        <Switch
                          checked={isStreetVisible}
                          size="small"
                          onChange={() => {
                            setIsStreetVisible((prevState) => !prevState);
                          }}
                        />
                      </Text>
                    </Col>
                  </Row>

                  <Row gutter={[24, 24]}>
                    <Col span={6}>
                      <div className="title-button">
                        {!isDrawingVisible && (
                          <RedoOutlined
                            style={{ fontSize: "36px", marginRight: "36px" }}
                            onClick={() => {
                              setIsDrawingVisible(true);
                            }}
                          />
                        )}
                        {/* <PrinterFilled
                          style={{ fontSize: "36px", marginRight: "36px" }}
                          onClick={printMap}
                        /> */}
                        {/* <SaveFilled style={{ fontSize: "36px" }} /> */}
                      </div>
                    </Col>
                  </Row>
                </div>
              }
              description={
                <MapGL
                  polyData={selectedDirections}
                  center={centerLoc}
                  zoom={zoom}
                  regionData={regionBoundary}
                  suburbData={suburbBoundary}
                  onChange={onDrawChange}
                />
              }
            />
          </Card>
        </div>
        <div className="bottom">
          <Card bordered={false}>
            {streetRawData.length > 0 && (
              <Row gutter={[24, 24]} className="custom-row">
                <Col span={12}>
                  <Text type="warning" level={3}>
                    Selected Addresses : {selectedCount}
                  </Text>
                </Col>
                <Col span={12}>
                  <Text type="success" level={3}>
                    Total Addresses : {totalCount}
                  </Text>
                </Col>

                <Col span={12}>
                  <Text level={4}>Select Confidence Level :</Text>
                </Col>

                <Col span={12}>
                  <Radio.Group
                    onChange={onConfidenceChange}
                    value={confidence}
                    defaultValue={confidence}
                  >
                    <Radio.Button value="1">1</Radio.Button>
                    <Radio.Button value="2">2</Radio.Button>
                    <Radio.Button value="BOTH">Both</Radio.Button>
                  </Radio.Group>
                </Col>

                <Col span={12}>
                  <Text level={4}>Select Flat Type :</Text>
                </Col>
                <Col span={12}>
                  <Radio.Group
                    onChange={onFlatTypeChange}
                    value={flatType}
                    defaultValue={flatType}
                  >
                    <Radio.Button value="HSE">House</Radio.Button>
                    <Radio.Button value="UNIT">Unit</Radio.Button>
                    <Radio.Button value="BOTH">Both</Radio.Button>
                  </Radio.Group>
                </Col>
              </Row>
            )}
            <Row gutter={[24, 24]} style={{ marginTop: "20px" }}>
              <Col span={24}>
                <CustomTable
                  data={streetList}
                  parentCallback={callback}
                  load={addressesLoading}
                />
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Home;
