import { initializeApp } from "firebase/app";
import {
  //   GoogleAuthProvider,
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import { collection, addDoc, getFirestore } from "firebase/firestore";

import { Modal } from "antd";

const firebaseConfig = {
  apiKey: "AIzaSyDNwNonIzSdgTmDZdE_x2bWdnEKTXDcmuA",
  authDomain: "flyersdirect-43e64.firebaseapp.com",
  projectId: "flyersdirect-43e64",
  storageBucket: "flyersdirect-43e64.appspot.com",
  messagingSenderId: "436721558866",
  appId: "1:436721558866:web:7acd1b66a02f06570dc68b",
  measurementId: "G-PSKWRMDZRK",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
// const googleProvider = new GoogleAuthProvider();

// const signInWithGoogle = async () => {
//   try {
//     const res = await signInWithPopup(auth, googleProvider);
//     const user = res.user;
//     const q = query(collection(db, "users"), where("uid", "==", user.uid));
//     const docs = await getDocs(q);
//     if (docs.docs.length === 0) {
//       await addDoc(collection(db, "users"), {
//         uid: user.uid,
//         name: user.displayName,
//         authProvider: "google",
//         email: user.email,
//       });
//     }
//   } catch (err) {
//     console.error(err);
//     alert(err.message);
//   }
// };

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    Modal.error({
      title: "There is an Error",
      content: err.message,
    });
  }
};

const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await addDoc(collection(db, "users"), {
      uid: user.uid,
      name,
      authProvider: "local",
      email,
    });
  } catch (err) {
    console.error(err);
    Modal.error({
      title: "There is an Error",
      content: err.message,
    });
  }
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    Modal.success({
      content: "Password reset link sent!",
    });
    alert("");
  } catch (err) {
    Modal.error({
      title: "There is an Error",
      content: err.message,
    });
  }
};

const logout = () => {
  signOut(auth);
};

export {
  auth,
  db,
  //   signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
};
