import React, { useEffect, useState } from "react";
import ReactMapGL, {
  FullscreenControl,
  NavigationControl,
  Source,
  Layer,
} from "react-map-gl";
import { LayerStyle, MatchLineStyle } from "../assets/css/MapBoxStyle";
import MapExportControl from "../utils/MapExportControl";
import { GetCoordinates } from "../utils/MapUtil";
import DrawControl from "./../utils/DrawControl";

const MapGL = (props) => {
  const [viewPort, setViewPort] = useState({});

  const [polylineData, setPolylineData] = useState(null);

  useEffect(() => {
    let data = [];

    props.regionData.forEach((item) => {
      let regionsListData = [];
      item.forEach((i) => {
        regionsListData.push([i.lng, i.lat]);
      });
      data.push({
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [regionsListData],
        },
      });
    });

    let suburbListData = [];
    if (props.suburbData.length === 1) {
      props.suburbData[0].forEach((i1) => {
        suburbListData.push([i1.lng, i1.lat]);
      });
      data.push({
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [suburbListData],
        },
      });
    }
    props.polyData.forEach((element) => {
      data.push(element.data);
    });

    setPolylineData({
      type: "FeatureCollection",
      features: data,
    });

    setViewPort({
      longitude: props.center.lng,
      latitude: props.center.lat,
      zoom: props.zoom,
    });
  }, [props]);

  const onMove = (e) => {
    localStorage.setItem("lat", e.viewState.latitude);
    localStorage.setItem("lng", e.viewState.longitude);
    localStorage.setItem("zoom", e.viewState.zoom);
    setViewPort(e.viewState);
  };

  const onDrawCreate = ({ features }) => {
    props.onChange({
      zoom: viewPort.zoom,
      coordinates: GetCoordinates(features[0]),
    });
  };

  const onDrawUpdate = ({ features }) => {
    console.log(features);
    props.onChange({
      zoom: viewPort.zoom,
      coordinates: GetCoordinates(features[0]),
    });
  };

  const onDrawDelete = ({ features }) => {
    const feature = features[0];
    console.log("feature", feature);
  };

  return (
    <ReactMapGL
      {...viewPort}
      mapStyle="mapbox://styles/davidsoo/claicomwh000a15mmcbiy5j1d"
      mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
      style={{ width: "49vw", height: "78vh" }}
      onMove={onMove}
    >
      <FullscreenControl position="bottom-left" />
      <NavigationControl />

      {viewPort.zoom >= 8 && (
        <DrawControl
          position="top-left"
          displayControlsDefault={false}
          onCreate={onDrawCreate}
          onUpdate={onDrawUpdate}
          onDelete={onDrawDelete}
          controls={{
            polygon: true,
            trash: true,
          }}
        />
      )}

      <Source id="my-data" type="geojson" data={polylineData}>
        <Layer {...MatchLineStyle} />
        <Layer {...LayerStyle} />
      </Source>

      <MapExportControl accessToken={process.env.REACT_APP_MAPBOX_TOKEN} />
    </ReactMapGL>
  );
};

export default MapGL;
