import React, { useState } from "react";
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  UserOutlined,
  InfoCircleOutlined,
  LockOutlined,
  MailOutlined,
} from "@ant-design/icons";

import { useHistory } from "react-router-dom";

import { Card, Input, Tooltip, Button, Modal } from "antd";

import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import { registerWithEmailAndPassword } from "../server/firebase";

const SignUp = () => {
  const navigate = useHistory();
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");

  const onSubmit = () => {
    if (password1 === password2) {
      registerWithEmailAndPassword(userName.length>0 ? userName : email, email, password1);
    } else {
      Modal.error({
        title: "Error",
        content: "Password did not match",
      });
    }
  };
  return (
    <div className="App-content">
      <Card
        title="Register"
        bordered={false}
        style={{
          width: "50vw",
          height: "50vh",
          maxWidth: "400px",
        }}
      >
        <br />
        <Input
          placeholder="Enter username"
          size="large"
          prefix={<UserOutlined />}
          value={userName}
          onChange={(e) => setUserName(e.target.value)}
          suffix={
            <Tooltip title="Please enter the username">
              <InfoCircleOutlined
                style={{
                  color: "rgba(0,0,0,.45)",
                }}
              />
            </Tooltip>
          }
        />
        <br />
        <br />
        <Input
          placeholder="Enter your email"
          size="large"
          prefix={<MailOutlined />}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          suffix={
            <Tooltip title="Please enter your Email">
              <InfoCircleOutlined
                style={{
                  color: "rgba(0,0,0,.45)",
                }}
              />
            </Tooltip>
          }
        />
        <br />
        <br />
        <Input.Password
          prefix={<LockOutlined />}
          value={password1}
          onChange={(e) => setPassword1(e.target.value)}
          size="large"
          placeholder="input password"
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
        />
        <br />
        <br />
        <Input.Password
          prefix={<LockOutlined />}
          value={password2}
          onChange={(e) => setPassword2(e.target.value)}
          size="large"
          placeholder="input password"
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
        />
        <br />
        <br />
        <Button size="large" type="primary" onClick={onSubmit} block>
          Sign Up
        </Button>

        <br />
        <br />
        <div className="link-button">
          Or
          <Button
            size="small"
            type="link"
            onClick={() => navigate.push("/login")}
          >
            Sign-In
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default SignUp;
