
import React ,{ useState, useEffect, useRef } from "react";
import { Button, Input, Space, Table, Card } from "antd";
import { SearchOutlined } from '@ant-design/icons';
// import { columns } from "../assets/Street/street-columns";
import { streetTypes } from "../data/dataModel";

const CustomTable = ({ data, parentCallback, load }) => {
  const [streetData, setStreetData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <label>{text}</label>
      ) : (
        text
      ),
  });


  const columns = [
    {
      title: "Street Name",
      dataIndex: "streetName",
      sorter: (a, b) => a.streetName.localeCompare(b.streetName),
      ...getColumnSearchProps('streetName'),
    },
    {
      title: "Street Type",
      dataIndex: "streetTypeCode",
      filters: streetTypes,
      onFilter: (value, record) => record.streetTypeCode.startsWith(value),
      filterSearch: true,
    },
    {
      title: "Suburb",
      dataIndex: "suburbName",
    },
    {
      title: "Houses #",
      dataIndex: "houseCount",
      sorter: (a, b) => a.houseCount - b.houseCount,
    },
    {
      title: "Unit #",
      dataIndex: "unitCount",
      sorter: (a, b) => a.unitCount - b.unitCount,
    },
  ];

  const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    let count = 0;
    selectedRows.forEach((element) => {
      count += element.houseCount + element.unitCount;
    });
    setSelectedRowKeys(newSelectedRowKeys);
    parentCallback({ count, keys: newSelectedRowKeys });
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  useEffect(() => {
    setStreetData(...[], data);
  }, [data]);

  return (
    <Card
      style={{
        height: "66vh",
        width: "100%",
        overflow: "auto",
      }}
    >
      <Table
        rowSelection={{
          type: "checkbox",
          ...rowSelection,
        }}
        rowKey={(record) => record.addressID}
        size="small"
        columns={columns}
        dataSource={streetData}
        loading={load}
      />
    </Card>
  );
};

export default CustomTable;
