import React, { useEffect, useState } from "react";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory,
} from "react-router-dom";
import Home from "./pages/Home";
import MapGL from "./pages/MapGL";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";

// Css
import "./App.css";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { Button, Typography } from "antd";
import { PoweroffOutlined } from "@ant-design/icons";
import { auth, logout } from "./server/firebase";
import { useAuthState } from "react-firebase-hooks/auth";

function App() {
  const navigate = useHistory();
  const [user, loading] = useAuthState(auth);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    if (user) {
      setIsAuthenticated(true);
    } else setIsAuthenticated(false);
  }, [user, loading, navigate]);

  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <Typography className="header-text">Flyers Direct</Typography>
          {isAuthenticated && (
            <Button
              ghost
              icon={<PoweroffOutlined />}
              onClick={() => {
                logout();
              }}
            >
              Sign Out
            </Button>
          )}
        </header>
        <Switch>
          <Route path="/map" component={MapGL} />

          <Route path="/home" component={Home} />
          <Route path="/login" component={Login} />
          <Route path="/register" component={SignUp} />
          <Redirect from="/" to="/home" />
        </Switch>
        <footer className="App-footer">
          <a href="https://orbigo.io/">&copy; orbigo.io</a>
        </footer>
      </div>
    </Router>
  );
}

export default App;
