import { useControl } from "react-map-gl";

import { MapboxExportControl } from "@watergis/mapbox-gl-export";
import "@watergis/mapbox-gl-export/css/styles.css";

function MapExportControl(props) {
  useControl(
    () => new MapboxExportControl(props),
    ({ map }) => {
      map.on("accessToken", props.accessToken);
    },
    ({ map }) => {
      map.off("accessToken", props.accessToken);
    }
  );

  return null;
}

export default MapExportControl;