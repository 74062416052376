import React, { useState, useEffect } from "react";

// import "antd/dist/antd.css"; // or 'antd/dist/antd.less'

import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  InfoCircleOutlined,
  LockOutlined,
  MailOutlined,
} from "@ant-design/icons";

import { Card, Input, Tooltip, Button, Modal } from "antd";
import { useHistory } from "react-router-dom";

import {
  auth,
  logInWithEmailAndPassword,
  sendPasswordReset,
} from "../server/firebase";
import { useAuthState } from "react-firebase-hooks/auth";

const Login = () => {
  const navigate = useHistory();
  const [user, loading] = useAuthState(auth);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (user) navigate.replace("/home");
  }, [user, loading, navigate]);

  const onSubmit = () => {
    console.log(email, password);
    logInWithEmailAndPassword(email, password);
  };

  const showModal = () => {
    Modal.confirm({
      title: (
        <div>
          <h3>Forgot your password</h3>
          <label>please enter you email</label>
        </div>
      ),
      content: (
        <div>
          <Input
            placeholder="Enter your email"
            size="large"
            prefix={<MailOutlined />}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            suffix={
              <Tooltip title="Please enter your Email">
                <InfoCircleOutlined
                  style={{
                    color: "rgba(0,0,0,.45)",
                  }}
                />
              </Tooltip>
            }
          />
        </div>
      ),
      okText: "Submit",
      cancelText: "Cancel",
      onOk: () => handleOk(),
      onCancel: () => {
        console.log("Cancel");
      },
    });
  };

  const handleOk = () => {
    console.log("Ok", email);
    sendPasswordReset(email);
  };

  return (
    <div className="App-content">
      <Card
        title="Sign In"
        bordered={false}
        style={{
          width: "50vw",
          height: "50vh",
          maxWidth: "400px",
        }}
      >
        <br />
        <Input
          placeholder="Enter your email"
          size="large"
          prefix={<MailOutlined />}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          suffix={
            <Tooltip title="Please enter your Email">
              <InfoCircleOutlined
                style={{
                  color: "rgba(0,0,0,.45)",
                }}
              />
            </Tooltip>
          }
        />
        <br />
        <br />
        <Input.Password
          prefix={<LockOutlined />}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          size="large"
          placeholder="input password"
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
        />

        <br />
        <br />
        <Button size="large" type="primary" onClick={onSubmit} block>
          Sign In
        </Button>

        <div className="forgot-password">
          <Button size="small" type="link" danger onClick={showModal}>
            Forgot Password?
          </Button>
        </div>
        <br />
        <br />

        <div className="link-button">
          No account?
          <Button
            size="small"
            type="link"
            onClick={() => navigate.push("/register")}
          >
            Register
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default Login;
